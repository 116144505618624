/*============= variables ==============*/

@import "./variables.scss";

.form-control {
  font-size: inherit;
  border: 1px solid $gray-500;
}
.input-group-text{
  border: 1px solid $gray-500;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input{
  padding: .75rem 1rem;
  cursor: text;
}
.form-label-group > label {
  padding: 1rem;
  cursor: text;
  /*padding: var(--input-padding-y) var(--input-padding-x);*/
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: $gray-700;
  /*border: 1px solid transparent;*/
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
  /*padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));*/
  /*padding-bottom: calc(var(--input-padding-y) / 3);*/
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.3rem;
  /*padding-bottom: 0.5rem;*/
 /* padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);*/
  font-size: 12px;
  color: $gray-600;
}
