/*============= fonts ==============*/
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400');
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import "./icomoon.scss";
/*============= variables ==============*/
@import "./variables.scss";

/*============= fontawesome ==============*/
@import "./fontawesome/fontawesome.scss";
@import "./fontawesome/light.scss";
@import "./fontawesome/regular.scss";
@import "./fontawesome/solid.scss";
@import "./fontawesome/brands.scss";

/*============= bootstrap ==============*/
@import "bootstrap/scss/bootstrap";
@import "./mixins.scss";


/*============= custom ==============*/

body {
  color: $theme-text;
  font-size: .875rem;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', Arial, sans-serif;
  background-color: #f2f3f8 !important;
  #root>.container-fluid>.row{
    max-height: 100vh;
  }

  overflow: hidden;
}

/*
 * Text
 */

h1,h2,h3,h4,h5,h6{
  font-family: 'Roboto', Arial, sans-serif;
}

h3,h4,h5 {
  color: #3f4047;
}

.h2 {
    color: $gray-700;
    font-weight: 300;
}

h5,h6{
  color: #575962;
}

small {
  font-weight: 300;
}

.text-light-gray {
  color:  $gray-600
}

a {
	color: $primary;
	text-decoration: none;
}

:focus {
  outline: #08f solid 2px !important;
}

/* When mouse is detected, ALL focused elements have outline removed. */
/* You could apply this selector only to buttons, if you wanted. */
body.using-mouse :focus {
  outline: none !important;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


/*
 * Card
 */

.card-koba {
  @include box-shadow(0px 1px 15px 1px rgba(69,65,78,0.08));
  background-color: white;
  padding: 2rem;
  .title{
    font-weight: 500;
    font-family: Roboto;
    font-size: 1.1rem;
    color: $gray-600;
    border-bottom: 1px solid #ebedf2;
    padding: 0 2rem 1.5rem 2rem;
    margin: 0 -2rem 2.4rem -2rem;
  }
  .new-slide {
    color: $gray-600;
  }
}

.card-save {
  border-top: 3px solid white;
  margin: 0 -1.5rem;
  background-color: $primary;
  color: white;
  bottom: 0;
  position: fixed;
  width: 83.33333%; /* bootstrap grid */
  z-index: 2;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}


/*
 * Array
 */

.koba-array {
  @include box-shadow(0px 1px 15px 1px rgba(69,65,78,0.08));
  thead tr th {
    border-top: 0;
    color: $primary;
    padding: 1.5rem 0.6rem;
    &:first-child {
      padding-left: 1.5rem;
    }
    &:last-child {
      padding-right: 1.5rem;
    }
  }


  tbody tr td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:first-child {
      padding-left: 1.5rem;
    }
    &:last-child {
      padding-right: 1.5rem;
    }
  }
}

/*
 * Content
 */

[role="main"] {
  margin-top: 64px; /* Space for fixed navbar */
  overflow-y: scroll;
  height: calc(100vh - 64px);
}

/* Used for the div right to the sidebar and below the top bar / submenu */
.content > div:not(.no-tabs) {
  margin-top: 3.5rem;
  overflow: hidden scroll;
  height: calc(100vh - 64px - 3.5rem);
}

/*
 * Table
 */

table {
  font-size: 0.775rem;
  th {
    cursor: pointer;
  }
}

.table {
  background-color: white !important;
  thead th {
    border-bottom: 0 !important;
  }
  td {
    border-top: 1px solid #f4f5f8;
    /*background: white;*/
  }
}

.table thead th, table td, .table-sm td {
  padding: 1rem 0.6rem;
  vertical-align: middle;
}

.table-action-btn {
  font-size: 10px;
  padding: 2px 4px;
}

.react-bootstrap-table {
  thead th:last-child {
    text-align: right;
    width: auto !important;
  }
}


.caret{
  font-family: 'Font Awesome 5 Pro';
  -webkit-font-smoothing: antialiased;
  color: $primary !important;
}
.dropdown{
  .caret:before {
      content: "\f0dc";
  }
}

.react-bootstrap-table-sort-order .caret:before {
  content: "\f0d7";
}

.react-bootstrap-table-sort-order.dropup .caret:before {
  content: "\f0de";
}

/* Modal */

.koba-modal-header {
  background-color: $gray-100;
  padding: 1rem 2rem;
  border-bottom: 3px solid #ff8550;
  h5 {
    color: gray;
  }
}

.modal-body{
  padding: 2rem;
}

/*
 * Buttons
 */
.modal-xl {
  max-width: 90% !important;
  max-height: 80% !important;
}

.btn-link-white {
  color: white !important;
}

.btn-link-white:hover {
  text-decoration: none !important;
}

.btn {
  font-size: .875rem;
}

.btn-outline-primary:hover {
  color: $white;
  background-color: $primary;
}

.btn-outline-secondary{
  color: #6b6b6f;
}

.btn-outline-secondary:hover {
  color: black;
/*  background-color: #949ff3;
  border-color: #949ff3;*/
}

.btn-square-36 {
  height: 36px;
  width: 36px;
  padding: 0;
}

.btn-action {
  color: $gray-800;
  background-color: $white;
  background-image: none;
  border-color: $gray-300;
  width: 2rem;
  height: 2rem;
}

.btn-action-lg {
  height: calc(2.25rem + 2px) !important;
  width: calc(2.25rem + 2px) !important;
}

.btn-action:hover {
  color: $white;
  background-color: $gray-600;
  border-color: $gray-600;
}

.btn-action-md {
  color: $gray-800;
  background-color: $white;
  background-image: none;
  border-color: $gray-400;
  width: 2.2rem;
  height: 2.2rem;
}

.btn-action-md:hover {
  color: $white;
  background-color: $gray-600;
  border-color: $gray-600;
}

.btn-outline-light:hover {
  color: $primary;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}


.btn-primary.disabled, .btn-primary:disabled {
  color: $white;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: $white;
}

/*
 * dropdown
 */

.dropdown-menu{
  border: 0;
  @include box-shadow(0px 0px 15px 1px rgba(69,65,78,0.2));
  font-size: 1rem;
  color: $theme-text-gray;
  a{
    color: $theme-text-gray;
  }
}


/* Used to center content */

.tb {
  display: table;
  height: 100%;
  width: 100%;
}

.tbc {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}


/*
 * Utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }
.primary-color { color: $primary; }
.border-left-separator { border-left: 2px $primary solid; }

.y-middle{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pointer{
  cursor: pointer;
}

.margin-for-publish{
  margin-bottom: calc(105px + 2rem);
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.action-list {
  list-style-type: none;
}

input.filter {
  display: none;
}
.disabled{
  opacity: .4;
  cursor: not-allowed;
}

/*
  Animations
 */
.bigger-on-hover{
  @include transition(all .2s cubic-bezier(.87,0,.29,1));
  &:hover {
    @include transform (scale(1.1));
  }
}


/*
 * Form
 */

button:focus, input:focus, .form-control:focus, input:active, label {
  outline: none;
  @include box-shadow(none !important);
}

button:active {
  border-color: transparent;
}

.form-control-dark {
  color: white;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  @include box-shadow(0 0 0 3px rgba(255, 255, 255, .25));
}

.form-control::placeholder {
  opacity: 0.85;
}

label {
  color: $gray-600;
  font-weight: 300;
}

.Select-control {
  height: calc(2.25rem + 2px);
}

.required {
  color: $primary;
}

/*============= inputs ==============*/

@import "./inputs.scss";
