/*
 * Mixins
 */

/*@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}*/

@mixin box-shadow($params) {
  -webkit-box-shadow: $params;
     -moz-box-shadow: $params;
          box-shadow: $params;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}


@mixin transform($transforms) {
  -moz-transform:   $transforms;
    -o-transform:   $transforms;
   -ms-transform:   $transforms;
-webkit-transform:  $transforms;
       transform:   $transforms;
}
