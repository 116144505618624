@font-face {
  font-family: 'icomoon';
  src: url('../fonts/Icomoon/icomoon.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-access"], [class*=" icon-access"] {
  /* use !important to prevent issues with browser extensions that change ../fonts */
  font-family: 'icomoon', serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-access-service-passeport:before {
  content: "\e907";
}

.icon-access-annuaire:before {
  content: "\e925";
}

.icon-access-video-play:before {
  content: "\e900";
}

.icon-access-account-user:before {
  content: "\e902";
}

.icon-access-agenda-arrow-left:before {
  content: "\e903";
}

.icon-access-agenda-arrow-right:before {
  content: "\e904";
}

.icon-access-close:before {
  content: "\e905";
}

.icon-access-link:before {
  content: "\e906";
}

.icon-access-chevron-circle-left:before {
  content: "\e909";
}

.icon-access-chevron-circle-right:before {
  content: "\e90a";
}

.icon-access-event-cal:before {
  content: "\e90b";
}

.icon-access-event-geo-point:before {
  content: "\e918";
}

.icon-access-event-hour:before {
  content: "\e90c";
}

.icon-access-event-info:before {
  content: "\e90d";
}

.icon-access-input-view:before {
  content: "\e90e";
}

.icon-access-input-upload:before {
  content: "\e90f";
}

.icon-access-input-eye:before {
  content: "\e910";
}

.icon-access-legend-fb:before {
  content: "\e911";
}

.icon-access-legend-photo:before {
  content: "\e912";
}

.icon-access-service-urbanisme:before {
  content: "\e919";
}

.icon-access-service-ordures:before {
  content: "\e91a";
}

.icon-access-service-contact:before {
  content: "\e91b";
}

.icon-access-service-sante2:before {
  content: "\e91c";
}

.icon-access-service-garde:before {
  content: "\e91d";
}

.icon-access-service-paiement:before {
  content: "\e91e";
}

.icon-access-service-etablissement:before {
  content: "\e91f";
}

.icon-access-service-juridique:before {
  content: "\e920";
}

.icon-access-service-tourisme:before {
  content: "\e921";
}

.icon-access-service-famille:before {
  content: "\e933";
}

.icon-access-service-democratie:before {
  content: "\e932";
}

.icon-access-service-loisir:before {
  content: "\e931";
}

.icon-access-service-dev-durable:before {
  content: "\e930";
}

.icon-access-service-immobilier:before {
  content: "\e92d";
}

.icon-access-service-emploi:before {
  content: "\e92c";
}

.icon-access-service-sante:before {
  content: "\e92b";
}

.icon-access-service-contact2:before {
  content: "\e92a";
}

.icon-access-service-pharmacie:before {
  content: "\e900";
}

.icon-access-service-menagere:before {
  content: "\e901";
}

.icon-access-service-etablissement:before {
  content: "\e903";
}

.icon-access-service-passeport:before {
  content: "\e907";
}

.icon-access-administratif_vote:before {
  content: "\e90e";
}

.icon-text-down:before {
  content: "\e901";
}

.icon-text-up:before {
  content: "\e900";
}

.icon-contrast:before {
  content: "\e902";
}

.icon-share-mail:before {
  content: "\e903";
}

.icon-map-town-hall:before {
  content: "\e904";
}

.icon-print:before {
  content: "\e923";
}

.icon-grid:before {
  content: "\e927";
}

.icon-hamburger:before {
  content: "\e928";
}

.icon-service-passeport:before {
  content: "\e907";
}

.icon-annuaire:before {
  content: "\e925";
}

.icon-video-play:before {
  content: "\e908";
}

.icon-account-user:before {
  content: "\e909";
}

.icon-agenda-arrrow-left:before {
  content: "\e90a";
}

.icon-agenda-arrrow-right:before {
  content: "\e90b";
}

.icon-close:before {
  content: "\e90c";
}

.icon-link:before {
  content: "\e90d";
}

.icon-chevron-circle-left:before {
  content: "\e924";
}

.icon-chevron-circle-right:before {
  content: "\e926";
}

.icon-event-cal:before {
  content: "\e92e";
}

.icon-event-geo-point:before {
  content: "\e92f";
}

.icon-event-hour:before {
  content: "\e911";
}

.icon-event-info:before {
  content: "\e912";
}

.icon-input-view:before {
  content: "\e913";
}

.icon-input-upload:before {
  content: "\e914";
}

.icon-input-eye:before {
  content: "\e915";
}

.icon-legend-fb:before {
  content: "\e916";
}

.icon-legend-photo:before {
  content: "\e917";
}

.icon-access-categ-ville:before {
  content: "\e919";
  font-size: 2em;
  border: solid white 1px;
  padding: 3px;
  color: white;
}

.icon-access-categ-administratif:before {
  content: "\e907";
  font-size: 2em;
  border: solid white 1px;
  padding: 3px;
  color: white
}

.icon-access-categ-loisir:before {
  content: "\e931";
  font-size: 2em;
  border: solid white 1px;
  padding: 3px;
  color: white;
}

.icon-access-categ-civique:before {
  content: "\e920";
  font-size: 2em;
  border: solid white 1px;
  padding: 3px;
  color: white;
}
