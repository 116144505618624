@import "./variables.scss";

div.koba-toast {
  color: white;
  background: $primary;
}

div.koba-danger-toast {
  background: $danger;
}
